<template>
  <div id="add-funds">
    <div class="title">Recharge</div>
    <div class="pay-method">
      <a-card title="Credit Card" style="margin-right:20px;">
        <div class="card-til" @click="clickCredit">
          <div class="type">Stripe</div>
          <div class="cal">
            <div class="des">Service Fee:2.9%+30cent</div>
            <img v-show="creditShow === true" src="../../assets/images/addfunds/funds1.svg" alt="">
            <img v-show="creditShow === false" src="../../assets/images/addfunds/funds2.svg" alt="">
          </div>
        </div>
      </a-card>
      <a-card :body-style="{ padding: 0 }">
        <template #title>
          <a-space>
            Bank Account
            <a-button shape="circle" @click="addBank" size="mini">
              <icon-plus />
            </a-button>
          </a-space>
        </template>
        <a-scrollbar :style="{ width: tableWidth.value - 400 + 'px', overflowX: 'scroll' }">
          <div class="add-bank">
            <div v-for="item, i in banklist.val" :key="item.id">
              <div class="back-card" v-if="item.verified === 1" @click="clickBank(i)">
                <div class="type">
                  <div class="card-num">
                    <span class="nickname">{{ item.nickname }}</span> <span>- {{ item.wh }}</span>
                  </div>
                  <a-button shape="round" status="success" size="mini" v-if="item.open_auto_charge === 1">Auto-Recharge
                  </a-button>
                </div>
                <div class="cal">
                  <div class="des">No Service Fee</div>
                  <img v-show="item.isShow === true" src="../../assets/images/addfunds/funds3.svg">
                  <img v-show="bankShow === false && current === i" src="../../assets/images/addfunds/funds4.svg">
                </div>
              </div>
              <div class="back-card-verify" v-else-if="item.verified === 0">
                <div class="type type-verify"><span class="nickname">{{ item.nickname }}</span> - {{ item.wh }}</div>
                <div class="cal">
                  <div class="des-verify" @click="verify(item.id)">Verify</div>
                  <img src="../../assets/images/addfunds/funds5.svg">
                </div>
              </div>
            </div>
          </div>
        </a-scrollbar>
      </a-card>
    </div>
    <a-row>
      <a-col :span="19">
        <div class="amount">
          <a-card>
            <template #title>
              <div class="box-title">
                Amount
                <span class="tip">Please enter amount between $100.00 ~ $20,000.00.</span>
              </div>
            </template>
            <div class="amount-cont">
              <a-form :model="form" ref="payForm" layout="vertical" autoComplet="off">
                <a-row :gutter="[20, 20]">
                  <a-col :span="4">
                    <a-form-item label="Add Amount" field="add_amount" :rules="[
                      {
                        required: true,
                        message: 'required'
                      },
                      { type: 'number', max: 20000, message: '$100.00～$20,000.00' },
                      { type: 'number', min: 100, message: '$100.00～$20,000.00' }
                    ]" :validate-trigger="['change', 'input', 'focus', 'blur']">
                      <a-input-number v-model="form.add_amount" :step="100" :formatter="limitPoint">
                        <template #prefix>
                          $
                        </template>
                      </a-input-number>
                    </a-form-item>
                  </a-col>
                  <a-col :span="4" v-show="creditShow === true">
                    <a-form-item label="Amount to Be Credited" field="amount_credited">
                      <a-input v-model="form.amount_credited">
                        <template #prefix>
                          $
                        </template>
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="4">
                    <div class="fee">
                      Service Fee:
                      <span>
                        &nbsp;<span v-if="creditShow === true">${{ form.serviceFee }}</span>
                        <span v-if="bankShow === false">$0.00</span>
                        <a-tooltip>
                          <template #content>
                            <div v-if="creditShow === true">
                              Service Fee:2.9%+30cent
                            </div>
                            <div v-if="bankShow === false">
                              No Service Fee
                            </div>
                          </template>
                          &nbsp;<icon-question-circle style="cursor: pointer"></icon-question-circle>
                        </a-tooltip>
                      </span>
                    </div>
                  </a-col>
                </a-row>
              </a-form>
              <div class="select-btn">
                <a-button shape="round" v-for="btn, index in quickOperate.val" :key="btn" @click="clickBtn(index)">{{
                  btn
                }}
                </a-button>
              </div>
            </div>
          </a-card>
        </div>
      </a-col>
      <a-col :span="19">
        <div class="amount">
          <a-card hoverable v-show="creditShow === true">
            <div class="box-title">
              Auto-recharge
              <span class="tip">Please choose bank account payment method to auto-recharge.</span>
            </div>
          </a-card>
          <a-card v-show="bankShow === false">
            <template #title>
              <div class="box-title">
                Auto-recharge
              </div>
            </template>
            <div class="amount-cont">
              <a-form :model="autoForm" ref="formAuto" autoComplet="off">
                <a-space size="large">
                  <a-switch checked-color="#13B966" unchecked-color="#F2F3F5" v-model="autoForm.isAuto" :checked-value="1"
                    :unchecked-value="0" @change="setAutoCharge">
                    <template #checked> ON </template>
                    <template #unchecked> OFF </template>
                  </a-switch>
                  <span class="auto-tip">Auto-recharge my bank account with </span>
                  <a-form-item hide-label field="autoPrice" :rules="[
                    {
                      required: true,
                      message: 'required'
                    },
                    { type: 'number', max: 20000, message: '$100.00～$20,000.00' },
                    { type: 'number', min: 100, message: '$100.00～$20,000.00' }
                  ]" :validate-trigger="['change', 'input', 'focus', 'blur']" style="margin-bottom:0;">
                    <a-input-number v-model="autoForm.autoPrice" :step="100" :formatter="limitPoint">
                      <template #prefix>
                        $
                      </template>
                    </a-input-number>
                  </a-form-item>
                  <span class="auto-tip">when my banlance drops below</span>
                  <a-form-item hide-label field="setBancel" :rules="[
                    {
                      required: true,
                      message: 'required'
                    },
                    { type: 'number', max: 1000, message: ` $${postPayLimit}.00～$1,000.00` },
                    { type: 'number', min: postPayLimit, message: `$${postPayLimit}.00～$1,000.00` }
                  ]" :validate-trigger="['change', 'input', 'focus', 'blur']" style="margin-bottom:0;">
                    <a-input-number v-model="autoForm.setBancel" :step="100" :formatter="limitPoint">
                      <template #prefix>
                        $
                      </template>
                    </a-input-number>
                  </a-form-item>
                </a-space>
                <!-- <a-row :gutter="[10]">
                  <a-col :span="2" style="padding:16px;">
                    <a-switch checked-color="#13B966" unchecked-color="#F2F3F5" v-model="autoForm.isAuto"
                      :checked-value="1" :unchecked-value="0" @change="setAutoCharge">
                      <template #checked> ON </template>
                      <template #unchecked> OFF </template>
                    </a-switch>
                  </a-col>
                  <a-col :span="6">
                    <span class="auto-tip">Auto-recharge my bank account with </span>
                  </a-col>
                  <a-col :span="4" style="padding-top:10px;">
                    <a-form-item hide-label field="autoPrice" :rules="[
                      {
                        required: true,
                        message: 'required'
                      },
                      { type: 'number', max: 20000, message: '$100.00～$20,000.00' },
                      { type: 'number', min: 100, message: '$100.00～$20,000.00' }
                    ]" :validate-trigger="['change', 'input', 'focus', 'blur']">
                      <a-input-number v-model="autoForm.autoPrice" :step="100" :formatter="limitPoint">
                        <template #prefix>
                          $
                        </template>
                      </a-input-number>
                    </a-form-item>
                  </a-col>
                  <a-col :span="5">
                    <span class="auto-tip">when my banlance drops below</span>
                  </a-col>
                  <a-col :span="4" id="setBancel" style="padding-top:10px;">
                    <a-form-item hide-label field="setBancel" :rules="[
                      {
                        required: true,
                        message: 'required'
                      },
                      { type: 'number', max: 1000, message: `Please enter the amount range from $${postPayLimit}.00～$1,000.00` },
                      { type: 'number', min: postPayLimit, message: `Please enter the amount range from $${postPayLimit}.00～$1,000.00` }
                    ]" :validate-trigger="['change', 'input', 'focus', 'blur']">
                      <a-input-number v-model="autoForm.setBancel" :step="100" :formatter="limitPoint">
                        <template #prefix>
                          $
                        </template>
                      </a-input-number>
                    </a-form-item>
                  </a-col>
                </a-row> -->
              </a-form>
            </div>
          </a-card>
        </div>
      </a-col>
      <a-col :span="19">
        <div class="amount" v-show="bankShow === false">
          <a-card title="Note">
            <a-textarea v-model="form.note" :auto-size="{
              minRows: 5
            }" placeholder="Please enter...
      " />
          </a-card>
        </div>
      </a-col>
    </a-row>
    <div class="submit-btn">
      <div v-show="bankShow === false">
        <a-form-item field="agree2">
          <a-checkbox v-model="form.agree2">I agree with <a :href="adslink + '/static/doc/Auto-recharge%20Agreement.pdf'"
              target="_blank">
              ADS
              auto-recharge agreement.</a>
          </a-checkbox>
        </a-form-item>
      </div>
      <div style="margin-right:24px;" class="pay-operate">
        <div>
          <a-space>
            <a-button shape="round">Recharge:</a-button>
            <span class="price" v-if="creditShow === true">${{ toThousands(form.amount_credited) }}</span>
            <span class="price" v-if="bankShow === false">${{ toThousands(form.add_amount) }}</span>
            <a-button class="normal-btn" type="primary" shape="round" @click="pay"
              :disabled="bankShow === false && form.agree2 === false">Pay</a-button>
          </a-space>
        </div>
        <div class="charge-by">
          By paying recharge,you agree to our<br />
          <a :href="adslink + '/static/doc/Privacy%20Policy.pdf'" target="_blank">company
            privacy</a> and <a :href="adslink + '/static/doc/Conditions%20of%20Use.pdf'" target="_blank">conditions of
            use.</a>
        </div>
        <div>
        </div>
      </div>
    </div>
    <!-- 添加银行卡弹框 -->
    <div class="mask">
      <a-modal v-model:visible="visible" @cancel="handleCancel" :render-to-body="false" title-align="center">
        <template #title>
          Add Bank Account
        </template>
        <div>
          <a-form :model="addForm" ref="formRef" layout="vertical" autoComplet="off">
            <a-form-item label="Nick Name" field="nickName" :rules="[
              {
                required: true,
                message: 'required'
              },
              { minLength: 3, maxLength: 30, message: '3-30 characters' }
            ]">
              <a-input v-model="addForm.nickName" placeholder="Required 3-30 characters" />
            </a-form-item>
            <a-form-item label="Name On Account" field="nameAccount" :rules="[
              {
                required: true,
                message: 'required'
              },
              { minLength: 3, maxLength: 30, message: '3-30 characters' }
            ]">
              <a-input v-model="addForm.nameAccount" placeholder="Required 3-30 characters" />
            </a-form-item>
            <a-form-item label="Account Type" field="accountType" :rules="[
              {
                required: true,
                message: 'required'
              }
            ]">
              <a-select placeholder="Please select ..." v-model="addForm.accountType">
                <a-option value="company">Business</a-option>
                <a-option value="individual">Individual</a-option>
              </a-select>
            </a-form-item>
            <a-form-item label="Rouiting Number(Bank)" field="rouitingNum" :rules="[
              {
                required: true,
                message: 'required'
              },
              { length: 9, message: 'The value should be 9 characters' }
            ]">
              <a-input v-model="addForm.rouitingNum" placeholder="Required 9 characters" />
            </a-form-item>
            <a-form-item label="Account Number(Bank)" field="accountNum" :rules="[
              {
                required: true,
                message: 'required'
              },
              { maxLength: 15, minLength: 9, message: 'The value shuould be 9-15 characters' }
            ]">
              <a-input v-model="addForm.accountNum" placeholder="Required 9-15 characters" />
            </a-form-item>
            <a-form-item label="Account Number Confirm" field="accountConfirm" :rules="[
              {
                required: true,
                message: 'required'
              },
              { maxLength: 15, minLength: 9, message: 'The value shuould be 9-15 characters' },
              { validator: validateConfirm }
            ]">
              <a-input v-model="addForm.accountConfirm" placeholder="Required 9-15 characters" />
            </a-form-item>
          </a-form>
        </div>
        <template #footer>
          <a-popconfirm content="Are you sure you want to reset?" type="warning" class="warnPop" @ok="resetForm"
            ok-text="Reset" cancel-text="Cancel">
            <a-button shape="round">Reset</a-button>
          </a-popconfirm>
          <a-button type="primary" shape="round" html-type="submit" @click="addBankSubmit">
            Submit</a-button>
        </template>
      </a-modal>
    </div>
    <!-- 验证银行卡弹框 -->
    <div class="verify">
      <a-modal v-model:visible="verifyVisible" @cancel="cancelVerify" :render-to-body="false" title-align="center">
        <template #title>
          Add Bank Account
        </template>
        <div class="warn">
          <img src="../../assets/images/order/waring2.png" alt="">
          <div>Please Note: The account will NEVER be verified if failed more than <span>10 times.</span></div>
        </div>
        <a-form :model="verifyForm" ref="formVerify" layout="vertical" autoComplet="off">
          <a-form-item label="Amount1" field="verify1" :rules="[
            {
              required: true,
              message: 'amount1 is required'
            },
            { match: /^0\.[0-9]{1,2}$/, message: 'validation error' }
          ]">
            <a-input v-model="verifyForm.verify1" placeholder="Eg:0.12" />
          </a-form-item>
          <a-form-item label="Amount2" field="verify2" :rules="[
            {
              required: true,
              message: 'amount2 is required'
            },
            { match: /^0\.[0-9]{1,2}$/, message: 'validation error' }
          ]">
            <a-input v-model="verifyForm.verify2" placeholder="Eg:0.56" />
          </a-form-item>
        </a-form>
        <template #footer>
          <a-button type="primary" shape="round" @click="verifyBank">Verify</a-button>
        </template>
      </a-modal>
    </div>
    <!-- 提交添加银行卡tips -->
    <div class="addTips">
      <a-modal v-model:visible="addTipsVisible" @cancel="addTipsCancel" :render-to-body="false" title-align="center"
        hide-cancel ok-text="OK" @ok="knowTips">
        <template #title>
          <a-space>
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0.166626 9.00008C0.166626 4.39771 3.89759 0.666748 8.49996 0.666748C13.1023 0.666748 16.8333 4.39771 16.8333 9.00008C16.8333 13.6025 13.1023 17.3334 8.49996 17.3334C3.89759 17.3334 0.166626 13.6025 0.166626 9.00008ZM9.33329 6.50008V4.83342H7.66663V6.50008H9.33329ZM7.66663 7.33342V13.1667H9.33329V7.33342H7.66663Z"
                fill="#13B966" />
            </svg>
            <span>
              Bank Account Submitted
            </span>
          </a-space>
        </template>
        <div>Stripe will make two small deposits to your account with description AMNTS. Please wait 1~2 business days
          and verify account with these two small deposits.</div>
      </a-modal>
    </div>
    <!-- 取消自动充值弹框 -->
    <a-modal v-model:visible="cancelAutoVisible" @cancel="cancelAuto" :render-to-body="false" title-align="center"
      ok-text="Turn Off" cancel-text="Cancel" @ok="okCancelAuoto">
      <template #title>
        <a-space>
          <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M0.166626 9.00008C0.166626 4.39771 3.89759 0.666748 8.49996 0.666748C13.1023 0.666748 16.8333 4.39771 16.8333 9.00008C16.8333 13.6025 13.1023 17.3334 8.49996 17.3334C3.89759 17.3334 0.166626 13.6025 0.166626 9.00008ZM7.66663 11.5001V13.1667H9.33329V11.5001H7.66663ZM9.33329 10.6667L9.33329 4.83342H7.66663L7.66663 10.6667H9.33329Z"
              fill="#FF7D00" />
          </svg>
          <span>Confirm Turn Off</span>
        </a-space>
      </template>
      <div>Are you sure you want to cancel auto-recharge? Once you turn off the switch, the auto-recharge agreement
        will lapse. You will fail to pay when the balance is insufficient.</div>
      <template #footer>
        <a-button shape="round" @click="turnoffCancel">Cancel</a-button>
        <a-button type="primary" status="danger" shape="round" @click="okCancelAuoto">Turn Off</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed, watch } from 'vue'
import { IconQuestionCircle, IconPlus } from '@arco-design/web-vue/es/icon'
import { stripeAchList, stripeAchCreate, stripeAchVerify, stripeAutoCharge, stripeDisableAutoCharge, stripePayCard, stripeAchPay } from '@/api/usercenter.js'
import { Modal, Notification } from '@arco-design/web-vue'
import local from '../../utils/local.js'
import { toThousands } from '@/utils/handleNumber.js'
import { useRouter } from 'vue-router'
// 使用loading
import { load } from '@/utils/loading.js'
const adslink = process.env.VUE_APP_ADSLINK
const router = useRouter()
const miniAchCharge = JSON.parse(local.get('userInfo')).minimum_ach_charge
const postPayLimit = -Number(JSON.parse(local.get('userInfo')).post_pay_limit)
const stripToken = JSON.parse(local.get('configInfo')).stripe_key
const subFlag = ref(true)
const form = reactive({
  add_amount: 100,
  amount_credited: '103.3',
  serviceFee: '',
  agree2: true,
  total: '',
  note: ''
})
const autoForm = reactive({
  isAuto: 0,
  autoPrice: 100,
  setBancel: 0
})
form.amount_credited = computed(() => {
  return (form.add_amount + form.add_amount / (1 / 0.029 - 1) + 0.3 / 0.029 / (1 / 0.029 - 1)).toFixed(2)
})
form.serviceFee = computed(() => {
  return (form.add_amount / (1 / 0.029 - 1) + 0.3 / 0.029 / (1 / 0.029 - 1)).toFixed(2)
})
// 验证form
const verifyForm = reactive({
  verify1: '',
  verify2: ''
})
const formRef = ref(null)
// 添加弹框
const visible = ref(false)
// 验证弹框0.12
const verifyVisible = ref(false)
// 点击添加银行卡
const addBank = () => {
  formRef.value.resetFields()
  visible.value = true
}
// 添加银行卡表单
const addForm = reactive({
  nickName: '',
  nameAccount: '',
  accountType: 'company',
  rouitingNum: '',
  accountNum: '',
  accountConfirm: ''
})
// 监测添加银行卡表单
watch(addForm, (newValue) => {
  if (
    addForm.nickName !== '' &&
    addForm.nameAccount !== '' &&
    addForm.accountType !== '' &&
    addForm.rouitingNum !== '' &&
    addForm.accountNum !== '' &&
    addForm.accountConfirm !== ''
  ) {
    subFlag.value = false
  } else {
    subFlag.value = true
  }
})
// 关闭弹框
const handleCancel = () => {
  formRef.value.resetFields()
  visible.value = false
}
let creditShow = ref(true)
let bankShow = ref(true)

// 点击creditCard
const clickCredit = () => {
  quickOperate.val = creditBtn
  form.add_amount = 100
  const creditCard = document.querySelector('.card-til')
  const bankAccount = document.querySelectorAll('.back-card')
  setTimeout(() => {
    const buttons = document.querySelectorAll('.select-btn .arco-btn')
    buttons.forEach(item => {
      item.classList.remove('checkedBtn')
    })
    buttons[0].classList.add('checkedBtn')
  }, 100)
  creditShow.value = true
  bankShow.value = true
  creditCard.style.backgroundColor = '#13B966'
  creditCard.children[0].style.color = '#fff'
  creditCard.children[1].style.color = '#fff'
  for (let b = 0; b < bankAccount.length; b++) {
    bankAccount[b].style.backgroundColor = '#F2F3F5'
    bankAccount[b].children[0].style.color = '#1D2129'
    bankAccount[b].children[1].style.color = '#4E5969'
    banklist.val[b].isShow = true
  }
}
const current = ref(null)
// 点击bank
const clickBank = (i) => {
  current.value = i
  quickOperate.val = bankBtn
  form.add_amount = 100
  const creditCard = document.querySelector('.card-til')
  const bankAccount = document.querySelectorAll('.back-card')
  setTimeout(() => {
    const buttons = document.querySelectorAll('.select-btn .arco-btn')
    buttons.forEach(item => {
      item.classList.remove('checkedBtn')
    })
    buttons[0].classList.add('checkedBtn')
  }, 100)
  creditCard.style.backgroundColor = '#F2F3F5'
  creditCard.children[0].style.color = '#1D2129'
  creditCard.children[1].style.color = '#4E5969'
  for (let j = 0; j < bankAccount.length; j++) {
    bankAccount[j].style.backgroundColor = '#F2F3F5'
    bankAccount[j].children[0].style.color = '#1D2129'
    bankAccount[j].children[1].style.color = '#4E5969'
    bankShow.value = true
    banklist.val[j].isShow = true
  }
  creditShow.value = false
  bankShow.value = false
  bankAccount[i].style.backgroundColor = '#13B966'
  bankAccount[i].children[0].style.color = '#fff'
  bankAccount[i].children[1].style.color = '#fff'
  banklist.val[i].isShow = false
  // 回填银行卡
  autoForm.setBancel = Number(banklist.val[i].lower_than)
  autoForm.isAuto = banklist.val[i].open_auto_charge
  if (banklist.val[i].auto_charge_amount === '') {
    autoForm.autoPrice = Number(miniAchCharge)
  } else {
    autoForm.autoPrice = Number(banklist.val[i].auto_charge_amount)
  }
}

const banklist = reactive({
  val: []
})
const account = ref(0)
// 获取银行卡列表
const getBankList = async () => {
  const msg = await stripeAchList({})
  if (msg.code === 0) {
    banklist.val = msg.data.banks
    banklist.val.forEach(item => {
      item.isShow = true
    })
    account.value = msg.data.count
    if (msg.data.default > 0 || msg.data.default === 0) {
      current.value = msg.data.default
      quickOperate.val = bankBtn
      form.add_amount = 100
      const creditCard = document.querySelector('.card-til')
      let bankAccount = reactive({ val: [] })
      setTimeout(() => {
        const buttons = document.querySelectorAll('.select-btn .arco-btn')
        buttons.forEach(item => {
          item.classList.remove('checkedBtn')
        })
        buttons[0].classList.add('checkedBtn')
        bankAccount.val = document.querySelectorAll('.back-card')
        creditCard.style.backgroundColor = '#F2F3F5'
        creditCard.children[0].style.color = '#1D2129'
        creditCard.children[1].style.color = '#4E5969'
        for (let j = 0; j < bankAccount.val.length; j++) {
          bankAccount.val[j].style.backgroundColor = '#F2F3F5'
          bankAccount.val[j].children[0].style.color = '#1D2129'
          bankAccount.val[j].children[1].style.color = '#4E5969'
          bankShow.value = true
          banklist.val[j].isShow = true
        }
        creditShow.value = false
        bankShow.value = false
        bankAccount.val[msg.data.default].style.backgroundColor = '#13B966'
        bankAccount.val[msg.data.default].children[0].style.color = '#fff'
        bankAccount.val[msg.data.default].children[1].style.color = '#fff'
        banklist.val[msg.data.default].isShow = false
      }, 100)
      // 回填银行卡
      autoForm.setBancel = Number(banklist.val[msg.data.default].lower_than)
      autoForm.isAuto = banklist.val[msg.data.default].open_auto_charge
      if (banklist.val[msg.data.default].auto_charge_amount === '') {
        autoForm.autoPrice = Number(miniAchCharge)
      } else {
        autoForm.autoPrice = Number(banklist.val[msg.data.default].auto_charge_amount)
      }
    }
  }
}
// 提交
const addBankSubmit = () => {
  formRef.value.validate(async (record) => {
    if (!record) {
      visible.value = false
      load.show('Submitting...')
      const msg = await stripeAchCreate({
        nickname: addForm.nickName,
        account_holder_name: addForm.nameAccount,
        account_holder_type: addForm.accountType,
        routing_number: addForm.rouitingNum,
        account_number: addForm.accountNum
      })
      if (msg.code === 0) {
        load.hide()
        addTipsVisible.value = true
        formRef.value.resetFields()
        visible.value = false
      } else {
        load.hide()
        Notification.error({
          title: 'Error',
          content: 'Submitted failed. Please try again.'
        })
      }
    }
  })
}
// 添加银行卡提示
const addTipsVisible = ref(false)
const addTipsCancel = () => {
  addTipsVisible.value = false
  getBankList()
}
const knowTips = () => {
  addTipsVisible.value = false
  getBankList()
}
// 重置添加 bank
const resetForm = () => {
  Modal.warning({
    title: 'Reset',
    hideCancel: false,
    titleAlign: 'start',
    renderToBody: false,
    okText: 'Yes',
    cancelText: 'Cancel',
    content: 'Do you want to clear all the order information?',
    onOk: () => {
      formRef.value.resetFields()
    }
  })
}
getBankList()
const verifyId = ref()
// 验证银行卡
const verify = (id) => {
  verifyForm.verify1 = ''
  verifyForm.verify2 = ''
  verifyId.value = id
  verifyVisible.value = true
}
const formVerify = ref(null)
// 取消验证
const cancelVerify = () => {
  verifyForm.verify1 = ''
  verifyForm.verify2 = ''
  verifyVisible.value = false
  formVerify.value.resetFields()
}
// 提交验证银行卡
const verifyBank = async () => {
  const msg = await stripeAchVerify({
    id: verifyId.value,
    amount1: verifyForm.verify1,
    amount2: verifyForm.verify2
  })
  if (msg.code === 0) {
    Notification.success({
      title: 'Success',
      content: 'Your bank accont is verified.'
    })
    verifyVisible.value = false
    getBankList()
  } else {
    if (msg.data.verify_count < 11 && msg.data.verify_count !== 0) {
      Notification.warning({
        title: 'Warning',
        content: `Bank Account Verification Failed(${msg.data.verify_count}/10)`
      })
      verifyForm.verify1 = ''
      verifyForm.verify2 = ''
    }
    if (msg.data.verify_count === 10) {
      Modal.error({
        title: 'Error',
        hideCancel: true,
        titleAlign: 'start',
        renderToBody: false,
        okText: 'OK',
        cancelText: 'Cancel',
        content: 'This bank account has failed to authenticate ten times and cannot be added permanently',
        onOk: () => {
          verifyVisible.value = false
          getBankList()
        }
      })
    }
  }
}
// 按钮快捷操作
const creditBtn = ['$100.00+$3.30', '$200.00+$6.28', '$500.00+$15.24', '$1000.00+$30.18', '$5000.00+$149.64', '$10000.00+$298.97']
const bankBtn = ['$100.00', '$200.00', '$500.00', '$1000.00', '$5000.00', '$10000.00']
let quickOperate = reactive({ val: creditBtn })
const clickBtn = (btnIndex) => {
  const buttons = document.querySelectorAll('.select-btn .arco-btn')
  for (let i = 0; i < buttons.length; i++) {
    buttons[i].classList.remove('checkedBtn')
  }
  buttons[btnIndex].classList.add('checkedBtn')
  let btnText = buttons[btnIndex].innerText
  form.add_amount = Number(btnText.split('+')[0].slice(1))
}
onMounted(() => {
  const buttons = document.querySelectorAll('.select-btn .arco-btn')
  buttons[0].classList.add('checkedBtn')
})
// 设置自动付款
const cancelAutoVisible = ref(false)
const setAutoCharge = async (val) => {
  if (val === 1) {
    if (form.agree2 === false) {
      Notification.warning({
        title: 'Warning',
        content: 'Please agree to the payment agreement.'
      })
      autoForm.isAuto = 0
      return false
    }
    if (autoForm.autoPrice < autoForm.setBancel) {
      Notification.warning({
        title: 'Warning',
        content: 'The amount of auto recharge cannot be less than the amount of banbance.'
      })
      autoForm.isAuto = 0
      return false
    }
    if (autoForm.setBancel < postPayLimit || autoForm.setBancel > 1000 || autoForm.setBancel === undefined) {
      autoForm.isAuto = 0
      return false
    }
    let bankName = ref('')
    for (let i = 0; i < banklist.val.length; i++) {
      if (banklist.val[i].open_auto_charge === 1) {
        bankName.value = banklist.val[i].description
      }
    }
    let tipContent = ''
    if (bankName.value !== '') {
      tipContent = `Using this account will cancel the auto-recharge of Bank Account: ${bankName.value}`
    } else {
      tipContent = 'Are you sure to open the auto-recharge of Bank Account?'
    }
    Modal.warning({
      title: 'Notice',
      hideCancel: false,
      titleAlign: 'start',
      renderToBody: false,
      okText: 'Use This Account',
      cancelText: 'Cancel',
      content: tipContent,
      onOk: async () => {
        load.show('Changing the recharge account.')
        const msg = await stripeAutoCharge({
          ach_id: banklist.val[current.value].id,
          auto_charge_amount: autoForm.autoPrice,
          lower_than: autoForm.setBancel
        })
        if (msg.code === 0) {
          load.hide()
          Notification.success({
            title: 'Success',
            content: 'The recharge account changed.'
          })
          getBankList()
        } else {
          load.hide()
          Notification.error({
            title: 'Error',
            content: msg.message
          })
          autoForm.isAuto = 0
        }
      },
      onCancel: () => {
        autoForm.isAuto = 0
      }
    })
  }
  if (val === 0) {
    cancelAutoVisible.value = true
  }
}
const cancelAuto = () => {
  getBankList()
}
const turnoffCancel = () => {
  cancelAutoVisible.value = false
  getBankList()
}
const okCancelAuoto = async () => {
  load.show('Turning Off...')
  const msg = await stripeDisableAutoCharge({
    ach_id: String(banklist.val[current.value].id)
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'Turned off auto-recharge.'
    })
    cancelAutoVisible.value = false
    getBankList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
    getBankList()
  }
}
// 输入框限制2位小数
const limitPoint = value => {
  let reg = /^(-)*(\d+)\.(\d\d).*$/
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '').replace(reg, '$1$2.$3')
}

// 验证确认银行账号
function validateConfirm (value, callback) {
  if (value !== addForm.accountNum) {
    callback(new Error('The two account number entered do not match.').message)
  } else {
    callback()
  }
}
const script = ref('')
onMounted(() => {
  script.value = document.createElement('script')
  script.value.src = 'https://checkout.stripe.com/checkout.js'
  document.body.appendChild(script.value)
})
const payForm = ref(null)
// 提交充值
const pay = () => {
  payForm.value.validate((record) => {
    if (!record) {
      // 充值信用卡
      if (creditShow.value) {
        const handler = StripeCheckout.configure({
          key: stripToken,
          image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
          locale: 'auto',
          token: async (token, addresses) => {
            const msg = await stripePayCard({
              stripeToken: token.id,
              stripeEmail: token.email,
              stripeBillingName: addresses.billing_name,
              stripeBillingAddressLine1: addresses.billing_address_line1,
              stripeBillingAddressZip: addresses.billing_address_zip,
              stripeBillingAddressState: addresses.billing_address_state,
              stripeBillingAddressCity: addresses.billing_address_city,
              stripeBillingAddressCountry: addresses.billing_address_country,
              amountcc: form.amount_credited
            })
          }
        })
        handler.open({
          name: 'ADS',
          description: 'Billing Infomation',
          billingAddress: true,
          amount: Math.round(form.amount_credited * 100)
        })
      } else {
        if (form.agree2 === false) {
          Notification.warning({
            title: 'Warning',
            content: 'Please agree to the payment agreement.'
          })
          return false
        }
        Modal.warning({
          title: 'Are you sure?',
          hideCancel: false,
          titleAlign: 'start',
          renderToBody: false,
          okText: 'Yes',
          cancelText: 'Cancel',
          content: 'The bank card will be charged ' + form.add_amount + '.',
          onOk: async () => {
            load.show('Pay...')
            // 充值银行卡
            const msg = await stripeAchPay({
              ach_id: banklist.val[current.value].id,
              amount: form.add_amount,
              note: form.note
            })
            if (msg.code === 0) {
              Notification.success({
                title: 'Success',
                content: banklist.val[current.value].description + ' payment Successful.'
              })
              load.hide()
              router.push('/usercenter/rechargesuccess?chargeVal=' + form.add_amount)
            } else {
              load.hide()
              Notification.error({
                title: 'Error',
                content: 'Payment failed. Please try again.'
              })
            }
          }
        })
      }
    }
  })
}
// 获取窗口的高度
const tableWidth = reactive({ value: 0 })
tableWidth.value = document.body.clientWidth
onMounted(() => {
  // 改变窗口高度
  window.onresize = () => {
    tableWidth.value = document.body.clientWidth
  }
})
</script>

<style lang="less" scoped>
#add-funds {
  position: relative;
  margin: 24px auto;
  margin-bottom: 140px;
}

// select
.title {
  font-weight: 500;
  font-size: 20px;
  color: var(--color-text-1);
  padding: 18px 24px 18px 48px;
}

.pay-method {
  display: flex;
  margin: 0px 24px;
}

.card-til {
  width: 264px;
  height: 64px;
  background-color: rgb(var(--primary-6));
  border-radius: 8px;
  padding: 16px;

  .type {
    color: #fff;
  }

  .cal {
    color: #fff;
  }
}

.card-name {
  color: #424B5D;
}

.credit-card {
  width: 304px;
  height: 132px;
  background: #fff;
  border-radius: 16px;
  padding: 16px;
}

.type {
  width: 264px;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-text-1);
}

.nickname {
  display: inline-block;
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-num {
  display: flex;
}

.type-verify {
  color: var(--color-text-4);
}

.cal {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  color: var(--color-text-2);

  .des {
    font-weight: 500;
    font-size: 12px;
  }

  .des-verify {
    font-weight: 500;
    font-size: 12px;
    text-decoration-line: underline;
    color: var(--color-text-1);
    cursor: pointer;
  }
}

.til {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 15px;
  color: #424B5D;
}

.bank-account {
  width: 878px;
  height: 132px;
  background-color: #fff;
  border-radius: 16px;
  margin-left: 24px;
  padding: 20px;
}

.add-bank {
  display: flex;
  // flex-flow: row nowrap;
  // overflow-x: auto;
  margin: 16px;
}

.back-card {
  width: 264px;
  height: 64px;
  background-color: #f7f8fa;
  border-radius: 8px;
  padding: 16px;
  margin-right: 16px;
  flex: 0 0 auto;
  cursor: pointer;
}

.back-card-verify {
  width: 264px;
  height: 56px;
  background-color: #f7f8fa;
  border-radius: 8px;
  padding: 20px;
  margin-right: 20px;
  flex: 0 0 auto;
}

// amount
.amount {
  margin: 24px 24px 0 24px;
}

.amount .box-title {
  font-weight: 500;
  font-size: 16px;
  color: var(--color-text-1);
}

.tip {
  font-weight: 400;
  font-size: 14px;
  color: var(--color-text-3);
  padding-left: 10px;
}

.amount-cont {
  :deep(.arco-form-item-label-required-symbol) {
    display: none;
  }
}

.fee {
  height: 96px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: var(--color-text-3);

  span {
    color: var(--color-text-1);
  }
}

.select-btn {
  .arco-btn {
    margin: 20px 20px 0 0;
  }
}

.btn2 {
  margin-top: 20px;
  padding-bottom: 20px;
}

.checkedBtn {
  background-color: rgb(var(--primary-6));
  color: #fff;

  &:hover {
    background-color: rgb(var(--primary-6)) !important;
    color: #fff;
  }
}

.auto-tip {
  height: 56px;
  line-height: 56px;
  font-weight: 400;
  font-size: 14px;
  color: var(--color-text-1);
}

:deep(.arco-checkbox-label) {
  a {
    font-weight: 700;
    font-size: 14px;
    color: rgb(var(--primary-6));
  }
}

.submit-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  height: 110px;
  border-top: 1px solid var(--color-border-2);
  left: 0;
  bottom: 0;
  background-color: #fff;

  .price {
    font-weight: 600;
    font-size: 24px;
    color: var(--color-text-1);
  }
}

.charge-by {
  margin-top: 16px;
  font-weight: 400;
  font-size: 12px;
  color: var(--color-text-3);

  a {
    font-weight: 700;
    font-size: 14px;
    color: rgb(var(--primary-6));
  }
}

.pay-operate {
  margin-right: 24px;
  flex: 1;
  text-align: right;
}

:deep(.arco-modal-footer) {
  text-align: center;
}

.warn {
  height: 56px;
  background: #FFE8E8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  img {
    margin-left: 16px;
    margin-right: 16px;
  }

  div {
    font-weight: 400;
    font-size: 12px;
    color: #251314;

    span {
      color: #fc5e5e;
    }
  }
}
</style>
<style>
.warnPop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--warning-6));
  background-color: rgb(var(--warning-1));
}
</style>
