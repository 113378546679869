// 引入封装的axios请求文件
import req from '../utils/request.js'

// 获取列表
export function stripeAchList (params) {
  return req.post('stripeAchList', params)
}

// 添加银行卡
export function stripeAchCreate (params) {
  return req.post('stripeAchCreate', params)
}

// 验证银行卡
export function stripeAchVerify (params) {
  return req.post('stripeAchVerify', params)
}

// 设置自动充值
export function stripeAutoCharge (params) {
  return req.post('stripeAutoCharge', params)
}

// 关闭自动充值
export function stripeDisableAutoCharge (params) {
  return req.post('stripeDisableAutoCharge', params)
}

// 充值credit
export function stripePayCard (params) {
  return req.post('stripePayCard', params)
}

// 充值bnak
export function stripeAchPay (params) {
  return req.post('stripeAchPay', params)
}

// 获取商家钱包信息
export function walletCount (params) {
  return req.post('walletCount', params)
}

// 获取shipments overview
export function WorkbenchShipmentOverview (params) {
  return req.post('WorkbenchShipmentOverview', params)
}

// 获取图表信息
export function WorkbenchStatisticsOverview (params) {
  return req.post('WorkbenchStatisticsOverview', params)
}

// claim list
export function claimList (params) {
  return req.post('claimList', params)
}

// claim add
export function claimAdd (params) {
  return req.post('claimAdd', params)
}
// claim close
export function claimClose (params) {
  return req.post('claimClose', params)
}

// claim detail
export function claimDetail (params) {
  return req.post('claimDetail', params)
}

// claim deit
export function claimEdit (params) {
  return req.post('claimEdit', params)
}

// claimCopy
export function claimCopy (params) {
  return req.post('claimCopy', params)
}
