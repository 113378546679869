export function toThousands (number) {
  number = Number(number)
  let sign
  if (number < 0) {
    sign = '-'
    number = -number;
  } else {
    sign = '';
  }
  number = number.toFixed(2);
  let arr = String(number).split('.');
  let intPart = arr[0];
  let deciPart = arr[1];
  let len = Math.ceil(intPart.length / 3) * 3;
  intPart = intPart.padStart(len, '0').match(/\d{3}/g).join(',').replace(/^0+/, '');
  if (intPart === '') {
    intPart = '0';
  }
  return sign + intPart + '.' + deciPart;
}
